import React from 'react'
import Navbar from "./navbar";

import header from '../img/header.jpg'

const Header = class extends React.Component {

  render() {
    return (
        <div>
            <img src={header} className="container image is-centered" alt="Gasthof Restaurant zur Einkehr"/>
        <Navbar />
      </div>
    )
  }
}

export default Header
