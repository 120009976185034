import React from 'react'
import { Link } from 'gatsby'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active,
      },
      () => {
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
        <div>
        <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
           
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
             
          </Link>
      
          <div 
          className={`navbar-burger burger ${this.state.navBarActiveClass}`} 
          aria-label="menu" 
          aria-expanded="false" 
          data-target="navbarBasicExample"
          onClick={() => this.toggleHamburger()}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>
      
        <div id="navbarBasicExample" className={`navbar-menu ${this.state.navBarActiveClass}`}>
          <div className="navbar-start">
            <Link className="navbar-item" to="/">
              HOME
            </Link>
      
            <div className="navbar-item has-dropdown is-hoverable ">
              <Link className="navbar-link is-arrowless">
                GASTHOF
              </Link>
              <div className="navbar-dropdown">
                <Link className="navbar-item" to="/gasthof/zimmer">
                  Zimmer
                </Link>
                <Link className="navbar-item" to="/gasthof/restaurant">
                  Restaurant
                </Link>
                <Link className="navbar-item" to="/gasthof/aufenthaltsraum">
                  Aufenthaltsraum
                </Link>
              </div>
            </div>

            <div className="navbar-item has-dropdown is-hoverable ">
              <Link className="navbar-link is-arrowless">
                FEWO
              </Link>
              <div className="navbar-dropdown">
                <Link className="navbar-item" to="/fewo/fewo">
                  Ferienwohnungen
                </Link>
                <Link className="navbar-item" to="/fewo/wohnung1">
                Wohnung 1
                </Link>
                <Link className="navbar-item" to="/fewo/wohnung2">
                Wohnung 2
                </Link>
                <Link className="navbar-item" to="/fewo/wohnung3">
                Wohnung 3
                </Link>
              </div>
            </div>

            <div className="navbar-item has-dropdown is-hoverable ">
              <Link className="navbar-link is-arrowless" to="/anreise/anreise">
                ANREISE
              </Link>
            </div>

            <div className="navbar-item has-dropdown is-hoverable ">
              <Link className="navbar-link is-arrowless" to="/preise/preise">
                PREISE
              </Link>
            </div>

            <div className="navbar-item has-dropdown is-hoverable ">
              <Link className="navbar-link is-arrowless">
                SOMMER
              </Link>
              <div className="navbar-dropdown">
                <Link className="navbar-item" to="/summer/almen">
                  Almen
                </Link>
                <Link className="navbar-item" to="/summer/huetten">
                  Hütten
                </Link>
                <Link className="navbar-item" to="/summer/gebirgssee">
                  Gebirgsseen
                </Link>
                <Link className="navbar-item" to="/summer/wandern">
                  Wandern
                </Link>
                <Link className="navbar-item" to="/summer/badespass">
                  Badespaß
                </Link>
                <Link className="navbar-item" to="/summer/kneipen">
                  Kneipen
                </Link>
                <Link className="navbar-item" to="/summer/wasserfall">
                  Wasserfälle
                </Link>
                <Link className="navbar-item" to="/summer/reiten">
                  Reiten
                </Link>
              </div>
            </div>

            <div className="navbar-item has-dropdown is-hoverable ">
              <Link className="navbar-link is-arrowless">
                WINTER
              </Link>
              <div className="navbar-dropdown">
                <Link className="navbar-item" to="/winter/gletscher">
                  Pitztaler Gletscher
                </Link>
                <Link className="navbar-item" to="/winter/riffelsee">
                  Riffelsee
                </Link>
                <Link className="navbar-item" to="/winter/langlaufen">
                  Langlaufen
                </Link>
              </div>
            </div>

            <div className="navbar-item has-dropdown is-hoverable ">
              <a className="navbar-link is-arrowless" href="/files/datenschutzinformationen.pdf">
                DATENSCHUTZ
              </a>
            </div>

            <div className="navbar-item has-dropdown is-hoverable ">
              <Link className="navbar-link is-arrowless" to="/kontakt/kontakt">
                KONTAKT
              </Link>
            </div>

            <div className="navbar-item has-dropdown is-hoverable ">
              <Link className="navbar-link is-arrowless" to="/open/open">
                ÖFFNUNGSZEITEN
              </Link>
            </div>

            <div className="navbar-item has-dropdown is-hoverable ">
              <Link className="navbar-link is-arrowless" to="/info/info">
                INFO
              </Link>
            </div>
          </div>
        </div>
        </nav>
      </div>
    )
  }
}

export default Navbar
