import React from 'react'
import { Helmet } from 'react-helmet'

import Header from "./header";

import "./styles.sass"


const Template = ({children}) => {

    return (
    <div className="container is-max-widescreen">
        <Helmet>
            <html lang="de"/>
            <title>Zur Einkehr</title>
            <meta name="description" content="Webseite Gasthof Restaurant Zur Einkehr."/>
        </Helmet>
        <Header />
        <div className="container is-fluid has-background-white main-card">
            {children}
        </div>
    </div>
    )
}

export default Template